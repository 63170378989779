<template>
  <v-container
      id="login"
      class="fill-height justify-center"
      tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>
    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center" v-show="!globalLoading">
      <v-slide-y-transition appear>
        <base-material-card
            color="success"
            light
            max-width="100%"
            width="400"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Please Enter OTP
              </h1>
            </div>
          </template>
          <form @submit="checkForm" novalidate="true">
            <v-card-text class="text-center">

              <v-otp-input
                  length="6"
                  label="OTP"
                  v-model="OTP"
              ></v-otp-input>

              <pages-btn
                  medium
                  color=""
                  depressed
                  class="mr-1 mb-2 v-btn--text success--text"
                  @click="checkForm"
              >
                <v-icon class="mr-1">mdi-fingerprint</v-icon>
                Submit
              </pages-btn>


            </v-card-text>
          </form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";
import externalMixins from "./mixins/index";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PagesLogin',

  mixins: [globalMixins, externalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    OTP: '',
    qToken: ''
  }),

  async created() {
    this.qToken = this.$router.currentRoute.query.token;
    this.globalLoading = false;
  },

  methods: {
    ...mapActions('auth', ['setToken', 'setUserAndACL']),

    async checkForm(e) {
      if (!this.OTP) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a valid OTP.'
        });
        return false;
      }

      await this.submit();
      e.preventDefault();
    },

    /* eslint-enable */
    async submit() {
      const self = this;
      return self.executeRequest({
        "method": "post",
        "url": "/otp",
        headers: {
          env: 'portal'
        },
        params: {
          "token": self.qToken,
          "otp": self.OTP
        }
      }).then(async response => {
        self.setToken(response.token);
        self.fetchUserAndAcl()
            .then(() => {
              self.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
            });
      }).catch(error => {
        self.handleErrorResponse(error);
      });
    },
  },

  computed: {
    ...mapGetters({user: 'auth/getUser', token: 'auth/getToken'}),
  }
}
</script>
