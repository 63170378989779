/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import {mapActions} from 'vuex';

export default {
	name: 'externalMixins',
	
	data() {
		return {}
	},
	
	components: {},
	
	computed: {},
	
	methods: {
		...mapActions('auth', ['setToken', 'setUserAndACL']),
		
		fetchUserAndAcl() {
			const self = this;
			self.globalLoading = true;
			return self.getSendData({
				url: '/user',
				method: 'get'
			}).then(response => {
				self.setUserAndACL(response);
			}).catch(error => {
				self.handleErrorResponse(error);
			}).finally(() => {
				self.globalLoading = false;
			});
		},
		
		handleErrorResponse(error) {
			if (error.errors) {
				for (let code in error.errors) {
					let oneError = error.errors[code];
					this.pushMessage({
						'type': 'error',
						'title': `Error: ${oneError.code}`,
						'text': oneError.message.toString()
					});
				}
			} else {
				this.pushMessage({
					'type': 'error',
					'title': 'Login Error',
					'text': error.toString()
				});
			}
		}
	}
}
